import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import Partner from '../../components/Index/Partner'

const Technologies = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <div className="testimonials-area pt-100 bg-f1f8fb" id="technologies">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="testimonial" />
                       Technologies
                    </span>
                    <h2>Solid foundations, <br />modern results</h2>
                    <p>As the Web matured, plentiful technologies became available. We opted to specialize in select few that offer the best utility, performance, maintainability and... of course results. We use solutions that are already market-tested and stable, have updates and decent support.</p>
                    <p>At the same time, solutions we use are modern and provide state-of-the-art user experience. Of course, your needs are unique but we know technologies flexible enough to cover them.</p>
                    <p>Still, we don't have to do everything by ourselves. Rather, we could integrate your app with various external services - be it payments, authentication, route finding or multitude of others.</p>
                </div>
            </div>
            <Partner/>
        </div>
    )
}

export default Technologies;