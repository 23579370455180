import React from 'react'
import service1 from '../../assets/images/services/service1.png'
import service2 from '../../assets/images/services/service2.png'
import starIcon from '../../assets/images/star-icon.png'

const Services = () => {
    return (
        <React.Fragment>
            {/* Service Left Image Style */}
            <div className="about-area pb-100 pt-100" id="ourServices">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={service1} alt="service" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="icon" /> Services
                                    </span>

                                    <h2>The stuff we do</h2>
                                    <p>You may wonder what kind of apps we actually make. Are we the right people to turn to? Let's make some cases, simplest first:</p>
                                    <ul>
                                        <li><em>Multi-page business website</em> - whether it's a site for company, NGO, charity or any other entity that has more - and growing - content to show, we can make it. Depending on your requirements it can be based on React frontend and backend made with a number of Node.js-based backend solutions. Either way, it offers modern looks with performant backend engine.</li>
                                        <li><em>Systems integration and automation</em> - you could need a payment system for parking lots, integrated with license plate scanner and a gate. Or maybe a system to estimate the number of parking places based on CCTV cameras and ground sensors? Or some booking system? Sure, why not? If only the subsystems needed to be integrated have some programming interface, we can do it.</li>
                                        <li><em>Personal web page</em> - it could be a professional portfolio or just virtual contact card. Simple, straightforward and pretty quick to make but you'll get a web presence like a pro. We will propose a site template, customize it and even copywrite its content. You get a modern website in your own web domain built on React + Gatsby - a recipe for catchy and snappy solution for your needs</li>
                                    </ul>
                                    <p>Ok, the list could grow on and on. The main takeaway is: for small to medium sized projects, we'll do it on our own. For larger ones we'd get assistance and do it too. We are well-connected in developer community and can enter in many kinds of ad-hoc business relationships to get the job done.</p>
                                    {/*ul className="content mb-0"> */}
                                    {/*    <li> */}
                                    {/*        <i className="flaticon-tick"></i> */}
                                    {/*        React.js */}
                                    {/*    </li> */}
                                    {/*    <li> */}
                                    {/*        <i className="flaticon-tick"></i> */}
                                    {/*         JavaScript & TypeScript */}
                                    {/*    </li> */}
                                    {/*    <li>*/}
                                    {/*        <i className="flaticon-tick"></i>*/}
                                    {/*        NodeJS*/}
                                    {/*    </li>*/}
                                    {/*    /!*<li>*!/*/}
                                    {/*    /!*    <i className="flaticon-tick"></i>*!/*/}
                                    {/*    /!*    Forex Trading*!/*/}
                                    {/*    /!*</li>*!/*/}
                                    {/*    <li>*/}
                                    {/*        <i className="flaticon-tick"></i>*/}
                                    {/*        Google Cloud Platform*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*        <i className="flaticon-tick"></i>*/}
                                    {/*        MySQL & PostgreSQL*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*        <i className="flaticon-tick"></i>*/}
                                    {/*        MongoDB*/}
                                    {/*    </li>*/}
                                    {/*    /!*<li>*!/*/}
                                    {/*    /!*    <i className="flaticon-tick"></i>*!/*/}
                                    {/*    /!*    Hybrid Cloud*!/*/}
                                    {/*    /!*</li>*!/*/}
                                    {/*</ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Left Image Style */}

            {/* Service Right Image Style */}
            <div className="our-mission-area pb-100 pt-100" id="approach">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="icon" />Approach
                                    </span>
                                    <h2>Agile development</h2>
                                    <p>You come to us with an idea and we start from scratch. First, we would work with you to flesh it out. When we have a big picture clear enough, we would decide what technologies to use in your specific case. You'll get a quotation and we will turn your ideas into a lo-fi prototype or mock-up.</p>
                                    <p>Then, we return to you and together we turn a list of your requirements into actionable stories and tasks. We embrace the Scrum methodology so we'd work closely with you, the Project Owner, in a loop of placing requirements, deciding on priorities, clarifying tasks we work on, actual development and demoing implemented features.</p>
                                    <p>At the end, you'll receive a product with features you currently need, nicely documented and thoroughly tested.</p>

                                    {/*<ul className="our-mission-list mb-0">*/}
                                    {/*    <li>*/}
                                    {/*        <i className="flaticon-tick"></i>*/}
                                    {/*        Responsive Design*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*        <i className="flaticon-tick"></i>*/}
                                    {/*        React Development*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*        <i className="flaticon-tick"></i>*/}
                                    {/*        Apps Development*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*        <i className="flaticon-tick"></i>*/}
                                    {/*        Laravel Development*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*        <i className="flaticon-tick"></i>*/}
                                    {/*        UX/UI Design*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*        <i className="flaticon-tick"></i>*/}
                                    {/*        E-commerce Design*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*        <i className="flaticon-tick"></i>*/}
                                    {/*        Web Design*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*        <i className="flaticon-tick"></i>*/}
                                    {/*        Print Ready Design*/}
                                    {/*    </li>*/}
                                    {/*</ul>*/}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img src={service2} alt="service" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Right Image Style */}
        </React.Fragment>
    )
}

export default Services;