import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import scrumIcon from '../../assets/images/services/service-icon1.png'
import fullCycleIcon from '../../assets/images/services/service-icon5.png'
import tailoredTechIcon from '../../assets/images/services/service-icon6.png'

const Advantages = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb" id="advantages">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="feature" />
                        Advantages
                    </span>

                    <h2>Why bet on us?</h2>
                    <p>There are many development teams and software houses out there. So, why do you choose us? Simple, we do the code long enough to do it right and do it well. At the same time, we are fresh enough to grasp new technologies and experienced enough to know when to apply them.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={scrumIcon} alt="feature" />
                            </div>
                            <h3>Scrum approach</h3>
                            <p>Agile is all the rage now, Scrum is a way to go. Yet, it's not about buzzwords but about clear communication between you - our customer and us - your development team. </p>
                            <p>We are well versed in establishing rapport with you, working along the lines of your priorities and communicating effectively to give you deliverables</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={fullCycleIcon} alt="feature" />
                            </div>
                            <h3>Full development cycle</h3>
                            <p>We offer complex services to cover your specific digital needs. You bring your idea, we work with you to outline and shape it, then plan it into deliverable milestones.</p>
                            <p>Once a plan comes together, you will get everything from user experience to internal architecture design, then its implementation and finally - deployment.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={tailoredTechIcon} alt="feature" />
                            </div>
                            <h3>Tech tailored to your needs</h3>
                            <p>IT came a long way and there is a multitude of technologies and solutions available. You could be overwhelmed - we, as professionals, are not. </p><p>We will build an architecture suitable for your use case and employ the right tools to ensure your app runs smoothly, is robust and available across the world.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Advantages
