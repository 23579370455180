import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'

const About = () => {
    return (
        <div className="pt-lg-2">
            <section className="solutions-area pb-70 pt-100" id="about">
                <div className="container">
                    <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="star" />
                        About
                    </span>
                        <h2>We create web apps from scratch</h2>
                        <p>We will turn your idea into a full-fledged online application, including designing user experience, building a catchy frontend, implementing logic specific to your cases and then deploying it so that it could be seen by the world at large.</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-6">
                            <div className="single-solutions-box">
                                <div className="icon">
                                    <i className="flaticon-rocket"></i>
                                </div>
                                <h3>
                                    <Link to="/service-details">
                                        Design
                                    </Link>
                                </h3>
                                <p>Design is not only about looks. Certainly, we will take care of it but also we will cover everything from implementing a consistent site-wide template to ensuring solid user experience and full accessibility.</p>

                                {/*<Link to="/service-details" className="view-details-btn">*/}
                                {/*    View Details*/}
                                {/*</Link>*/}
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                            <div className="single-solutions-box">
                                <div className="icon">
                                    <i className="flaticon-laptop"></i>
                                </div>

                                <h3>
                                    <Link to="/service-details">
                                        Apps and websites
                                    </Link>
                                </h3>

                                <p>From small single-page apps to complex solutions employing intricate business logic, we will create well-matched front- and backend. Tailored to your needs, you'll get a modern and sturdy piece of software.</p>

                                {/*<Link to="/service-details" className="view-details-btn">*/}
                                {/*    View Details*/}
                                {/*</Link>*/}
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                            <div className="single-solutions-box">
                                <div className="icon">
                                    <i className="flaticon-money"></i>
                                </div>

                                <h3>
                                    <Link to="/service-details">
                                        App maintenance
                                    </Link>
                                </h3>

                                <p>We know that your needs would evolve. <br/><br/>
                                    Call us and we will expand your app to account for new functionalities, with stability, security and performance in mind.</p>

                                {/*<Link to="/service-details" className="view-details-btn">*/}
                                {/*    View Details*/}
                                {/*</Link>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default About;
