import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import project1 from '../../assets/images/projects/project1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import project3 from '../../assets/images/projects/project3.jpg'
import project4 from '../../assets/images/projects/project4.jpg'
import project5 from '../../assets/images/projects/project5.jpg'
import project6 from '../../assets/images/projects/project6.jpg'

const Development = () => {
    return (
        <section className="projects-area bg-color pt-100 pb-70" id="development" >
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="project" /> Development
                    </span>
                    <h2>Areas of expertise</h2>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box">
                            {/*<div className="image">*/}
                                <img src={project1} alt="project" />

                                {/*<Link className="link-btn" to="/case-studies-details">*/}
                                {/*    <i className='bx bx-plus'></i>*/}
                                {/*</Link>*/}
                            {/*</div>*/}

                            <div className="content">
                                <h3>
                                        Frontend Development
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box">
                            {/*<div className="image">*/}
                                <img src={project2} alt="project" />

                                {/*<Link className="link-btn" to="/case-studies-details">*/}
                                {/*    <i className='bx bx-plus'></i>*/}
                                {/*</Link>*/}
                            {/*</div>*/}

                            <div className="content">
                                    <h3>
                                        Backend Development
                                    </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box">
                            {/*<div className="image">*/}
                                <img src={project3} alt="project" />

                            {/*    <Link className="link-btn" to="/case-studies-details">*/}
                            {/*        <i className='bx bx-plus'></i>*/}
                            {/*    </Link>*/}
                            {/*</div>*/}

                            <div className="content">
                                    <h3>
                                        UX/UI Design
                                    </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box">
                            {/*<div className="image">*/}
                                <img src={project4} alt="project" />

                                {/*<Link className="link-btn" to="/case-studies-details">*/}
                                {/*    <i className='bx bx-plus'></i>*/}
                                {/*</Link>*/}
                            {/*</div>*/}

                            <div className="content">
                                <h3>
                                    <h3>
                                        Devops
                                    </h3>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box ">
                            {/*<div className="image">*/}
                                <img src={project5} alt="project" />

                                {/*<Link className="link-btn" to="/case-studies-details">*/}
                                {/*    <i className='bx bx-plus'></i>*/}
                                {/*</Link>*/}
                            {/*</div>*/}

                            <div className="content">
                                <h3>
                                    <h3>
                                        Maintenance
                                    </h3>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box">
                            {/*<div className="image">*/}
                                <img src={project6} alt="project" />

                            {/*    <Link className="link-btn" to="/case-studies-details">*/}
                            {/*        <i className='bx bx-plus'></i>*/}
                            {/*    </Link>*/}
                            {/*</div>*/}

                            <div className="content">
                                <h3>
                                    <h3>
                                        Support
                                    </h3>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Development;