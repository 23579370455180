import React from "react"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/Index/Banner"
import About from "../components/Index/About"
import Services from "../components/Index/Services"
import Advantages from "../components/Index/Advantages"
import Founders from "../components/Index/Founders"
import Development from "../components/Index/Development"
import Technologies from "../components/Index/Technologies"
import ProjectStartArea from "../components/Index/ProjectStartArea"
import NavbarTwo from "../components/App/NavbarTwo";

const Home = () => {

    if (typeof window !== "undefined") {
        // eslint-disable-next-line global-require
        require("smooth-scroll")('a[href*="#"]')
    }


    return (
    <Layout id="home">
      <NavbarTwo />
      <Banner />
      <About/>
      <Services/>
      <Advantages/>
      {/*<Founders id="founders" />      /!*<Founders id="founders" />*/}
      <Development/>
      <Technologies/>
      <ProjectStartArea />
      <Footer />
    </Layout>
  )
}

export default Home
